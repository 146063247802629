<template>
  <MoleculesSelection
    v-if="type === 'radio'"
    v-model="currValue"
    name="states"
    type="radio"
    :ticker="radioType"
    :options="STATES"
    :error-message="errorMessage"
    :cols="2"
    :mobile-cols="2"
    :list-wrapper-class="wrapperClass"
    @update="handleValidation"
  />

  <MoleculesSelectDropdown
    v-else
    ref="states"
    v-model="currValue"
    :label="label"
    :name="$attrs.name || 'state'"
    :placeholder="$attrs.placeholder || 'Select your state'"
    model-type="dropdown"
    :data-list-display="dataListDisplay"
    :data-input-display="dataInputDisplay"
    :data-value="dataValue"
    :error-message="errorMessage"
    :data-arr="STATES"
    :theme="theme"
    @validation="handleValidation"
  />
</template>

<script setup>
import { STATES } from '~/constants/states.constants'

defineOptions({
  name: 'MoleculesStates'
})

const props = defineProps({
  modelValue: {
    type: [String, Object],
    default: null
  },

  label: {
    type: String,
    default: 'State'
  },

  type: {
    type: String,
    default: 'input',
    validator: value => ['input', 'radio'].includes(value)
  },

  /**
   * key used to display data in dropdown
   */
  dataListDisplay: {
    type: [String, Number],
    default: 'name'
  },

  /**
   * key used to display data in <input>
   */
  dataInputDisplay: {
    type: [String, Number],
    default: 'name'
  },

  /**
   * key used for emitting data to parent component
   * if value is empty it return the whole selected item
   */
  dataValue: {
    type: [String, Number],
    default: 'value'
  },

  errorMessage: {
    type: String,
    default: ''
  },

  theme: {
    type: String,
    default: 'default'
  },

  radioType: {
    type: String,
    default: 'none'
  }
})

const wrapperClass = computed(() => {
  if (props.radioType === 'circle') {
    return 'md:text-lg'
  }
  return 'md:text-lg font-bold text-center'
})

const emit = defineEmits([
  'update:modelValue',
  'update',
  'country-validation'
])

const currValue = proxyModel(props, 'modelValue')

watch(currValue, async () => {
  await nextTick()

  if (currValue.value) {
    emit('update', currValue.value)
  }
}, { deep: true })

if (
  (currValue.value || props.modelValue) &&
  props.type !== 'radio'
) {
  emit('update', currValue.value || props.modelValue)
}

function handleValidation (value) {
  emit('country-validation', value)
}
</script>
